<template>
  <div class="container">
    <loading
        :active.sync="isLoadingOverlay"
        :color="'#408521'"
        :is-full-page="false"
    ></loading>

    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
        <div class="row" v-if="$route.params.category">
          <div class="col-md-12">
            <div class="archive-header text-center">
              <div class="archive-title">
                Records by category:
                <span class="archive-name">{{
                    $globalFunctions.getCategoryByAlias($route.params.category)
                        .en_name
                  }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="$route.params.region">
          <div class="col-md-12">
            <div class="archive-header text-center">
              <div class="archive-title">
                Records by region:
                <span class="archive-name">{{ $globalFunctions.getTranslatedRegion($route.params.region) }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="$route.params.author">
          <div class="col-md-12">
            <div class="archive-header text-center">
              <div class="archive-title">
                Records by author:
                <span class="archive-name">{{
                    $globalFunctions.getUserByDisplayName($route.params.author)
                        .displayName
                  }}</span>
              </div>
            </div>

            <UserAvatarWidget
                :user-id="
                $globalFunctions.getUserByDisplayName($route.params.author).id
              "
                :location="''"
            ></UserAvatarWidget>
          </div>
        </div>

        <div class="row">
          <!-- HERO POST-->
          <div class="col-md-12" v-if="heroPost">
            <article class="single-blog">
              <div class="post-thumb">
                <a
                    class="mx-auto w-100"
                    @click.stop="
                    () => {
                      $router.push({
                        name: 'EN_Post',
                        params: { postId: heroPost.id }
                      });
                    }
                  "
                >
                  <img :src="heroPost.photos[0]" :alt="heroPost.title"
                  /></a>
              </div>
              <div class="post-content">
                <div class="entry-header text-center text-uppercase">
                  <a
                      @click="
                      () => {
                        $router
                          .push({
                            name: 'EN_HomeWithCategoryFilter',
                            params: {
                              category: $globalFunctions.getCategoryById(
                                heroPost.categoryId
                              ).alias
                            }
                          })
                          .catch(() => {});
                      }
                    "
                      class="post-cat"
                  >{{
                      $globalFunctions.getCategoryById(heroPost.categoryId).name
                    }}</a
                  >
                  <h2>
                    <a
                        @click.stop="
                        () => {
                          $router.push({
                            name: 'EN_Post',
                            params: { postId: heroPost.id }
                          });
                        }
                      "
                    >{{ heroPost.title }}</a
                    >
                  </h2>
                </div>
                <div class="entry-content">
                  <p>
                    {{ heroPost.summary }}
                  </p>
                </div>
                <div class="continue-reading text-center text-uppercase">
                  <a
                      @click.stop="
                      () => {
                        $router.push({
                          name: 'EN_Post',
                          params: { postId: heroPost.id }
                        });
                      }
                    "
                  >Več</a
                  >
                </div>
                <div class="post-meta">
                  <ul class="pull-left list-inline author-meta">
                    <li class="author list-inline-item">
                      Zapisal/-a<a
                        class="pl-1"
                        @click="
                          () => {
                            $router
                              .push({
                                name: 'EN_HomeWithAuthorFilter',
                                params: {
                                  author: $globalFunctions.getUserById(
                                    heroPost.createdBy
                                  ).displayName
                                }
                              })
                              .catch(() => {});
                          }
                        "
                    >{{
                        $globalFunctions.getUserById(heroPost.createdBy)
                            .displayName
                      }}</a
                    >,
                    </li>
                    <li class="date list-inline-item">
                      {{
                        moment
                            .unix(heroPost.confirmedAt.seconds)
                            .format("DD. MMMM, YYYY")
                      }}
                    </li>
                    <li
                        class="list-inline-item float-lg-right font-normal float-none my-2 my-lg-0"
                    >
                      <a
                          class="regionLink"
                          @click="
                          () => {
                            $router
                              .push({
                                name: 'EN_HomeWithRegionFilter',
                                params: {
                                  region: heroPost.region
                                }
                              })
                              .catch(() => {});
                          }
                        "
                      >
                        {{ heroPost.region.toUpperCase() }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </article>
          </div>
          <!-- end of HERO POST-->

          <div class="col-md-12">
            <div v-if="isLoading">
              <div class="row">
                <div class="col-md-12">
                  <div class="ph-item">
                    <div class="single-blog post-list">
                      <div class="media">
                        <div class="media-left">
                          <div class="post-thumb">
                            <a>
                              <div class="post-thumb">
                                <div
                                    class="ph-picture"
                                    style="height: 100%"
                                ></div>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div class="media-body">
                          <div class="post-content">
                            <div class="ph-row">
                              <div class="ph-col-8"></div>
                              <div class="ph-col-12 empty"></div>
                              <div class="ph-col-12 empty"></div>
                              <div class="ph-col-12 big"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-8"></div>
                              <div class="ph-col-12 empty"></div>
                              <div class="ph-col-12 empty"></div>
                              <div class="ph-col-6"></div>
                              <div class="ph-col-10"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="ph-item">
                    <div class="single-blog post-list">
                      <div class="media">
                        <div class="media-left">
                          <div class="post-thumb">
                            <a>
                              <div class="post-thumb">
                                <div
                                    class="ph-picture"
                                    style="height: 100%"
                                ></div>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div class="media-body">
                          <div class="post-content">
                            <div class="ph-row">
                              <div class="ph-col-8"></div>
                              <div class="ph-col-12 empty"></div>
                              <div class="ph-col-12 empty"></div>
                              <div class="ph-col-12 big"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-8"></div>
                              <div class="ph-col-12 empty"></div>
                              <div class="ph-col-12 empty"></div>
                              <div class="ph-col-6"></div>
                              <div class="ph-col-10"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="ph-item">
                    <div class="single-blog post-list">
                      <div class="media">
                        <div class="media-left">
                          <div class="post-thumb">
                            <a>
                              <div class="post-thumb">
                                <div
                                    class="ph-picture"
                                    style="height: 100%"
                                ></div>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div class="media-body">
                          <div class="post-content">
                            <div class="ph-row">
                              <div class="ph-col-8"></div>
                              <div class="ph-col-12 empty"></div>
                              <div class="ph-col-12 empty"></div>
                              <div class="ph-col-12 big"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-8"></div>
                              <div class="ph-col-12 empty"></div>
                              <div class="ph-col-12 empty"></div>
                              <div class="ph-col-6"></div>
                              <div class="ph-col-10"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="ph-item">
                    <div class="single-blog post-list">
                      <div class="media">
                        <div class="media-left">
                          <div class="post-thumb">
                            <a>
                              <div class="post-thumb">
                                <div
                                    class="ph-picture"
                                    style="height: 100%"
                                ></div>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div class="media-body">
                          <div class="post-content">
                            <div class="ph-row">
                              <div class="ph-col-8"></div>
                              <div class="ph-col-12 empty"></div>
                              <div class="ph-col-12 empty"></div>
                              <div class="ph-col-12 big"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-8"></div>
                              <div class="ph-col-12 empty"></div>
                              <div class="ph-col-12 empty"></div>
                              <div class="ph-col-6"></div>
                              <div class="ph-col-10"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="ph-item">
                    <div class="single-blog post-list">
                      <div class="media">
                        <div class="media-left">
                          <div class="post-thumb">
                            <a>
                              <div class="post-thumb">
                                <div
                                    class="ph-picture"
                                    style="height: 100%"
                                ></div>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div class="media-body">
                          <div class="post-content">
                            <div class="ph-row">
                              <div class="ph-col-8"></div>
                              <div class="ph-col-12 empty"></div>
                              <div class="ph-col-12 empty"></div>
                              <div class="ph-col-12 big"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-8"></div>
                              <div class="ph-col-12 empty"></div>
                              <div class="ph-col-12 empty"></div>
                              <div class="ph-col-6"></div>
                              <div class="ph-col-10"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="ph-item">
                    <div class="single-blog post-list">
                      <div class="media">
                        <div class="media-left">
                          <div class="post-thumb">
                            <a>
                              <div class="post-thumb">
                                <div
                                    class="ph-picture"
                                    style="height: 100%"
                                ></div>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div class="media-body">
                          <div class="post-content">
                            <div class="ph-row">
                              <div class="ph-col-8"></div>
                              <div class="ph-col-12 empty"></div>
                              <div class="ph-col-12 empty"></div>
                              <div class="ph-col-12 big"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-8"></div>
                              <div class="ph-col-12 empty"></div>
                              <div class="ph-col-12 empty"></div>
                              <div class="ph-col-6"></div>
                              <div class="ph-col-10"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="ph-item">
                    <div class="single-blog post-list">
                      <div class="media">
                        <div class="media-left">
                          <div class="post-thumb">
                            <a>
                              <div class="post-thumb">
                                <div
                                    class="ph-picture"
                                    style="height: 100%"
                                ></div>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div class="media-body">
                          <div class="post-content">
                            <div class="ph-row">
                              <div class="ph-col-8"></div>
                              <div class="ph-col-12 empty"></div>
                              <div class="ph-col-12 empty"></div>
                              <div class="ph-col-12 big"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-12"></div>
                              <div class="ph-col-8"></div>
                              <div class="ph-col-12 empty"></div>
                              <div class="ph-col-12 empty"></div>
                              <div class="ph-col-6"></div>
                              <div class="ph-col-10"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" v-if="posts && posts.length > 0 && !isLoading">
              <div class="col-md-12" v-for="post in posts" :key="post.id">
                <article class="single-blog post-list">
                  <div class="media">
                    <div class="media-left">
                      <div
                          class="post-thumb"
                          v-if="post.photos && post.photos[0]"
                      >
                        <a
                            @click.exact="
                            () => {
                              $router.push({
                                name: 'EN_Post',
                                params: { postId: post.id }
                              });
                            }
                          "
                            @click.shift="
                            () => {
                              let routeData = $router.resolve({
                                name: 'EN_Post',
                                params: { postId: post.id }
                              });
                              openLinkInNewTab(routeData.href);
                            }
                          "
                        >
                          <img
                              :src="
                              $globalFunctions.getOptimizedImage(post.photos[0])
                            "
                              :alt="post.title"
                          /></a>
                      </div>
                    </div>
                    <div class="media-body">
                      <div class="post-content">
                        <div class="entry-header text-left text-uppercase mb-0">
                          <div class="postUserAvatarAndBadge">
                            <UserAvatarWidget
                                :user-id="post.createdBy"
                                :location="'Home'"
                            ></UserAvatarWidget>

                            <img
                                v-if="post.recordFromNationalRegistry"
                                :src="
                                require('@/assets/images/iz-registra-zig.svg')
                              "
                                alt="Iz registra nesnovne kulturne dediščine"
                                class="img-responsive stamp"
                                style="height: 55px; height: 55px; object-fit: cover; margin-top: -20px;"
                            />
                          </div>
                          <a
                              @click="
                              () => {
                                $router
                                  .push({
                                    name: 'EN_HomeWithCategoryFilter',
                                    params: {
                                      category: $globalFunctions.getCategoryById(
                                        post.categoryId
                                      ).alias
                                    }
                                  })
                                  .catch(() => {});
                              }
                            "
                              class="post-cat mb-0"
                          >{{
                              $globalFunctions.getCategoryById(post.categoryId)
                                  .en_name
                            }}</a
                          >
                          <h2 class="my-0 pt-1">
                            <a
                                @click.exact="
                                () => {
                                  $router.push({
                                    name: 'EN_Post',
                                    params: { postId: post.id }
                                  });
                                }
                              "
                                @click.shift="
                                () => {
                                  let routeData = $router.resolve({
                                    name: 'EN_Post',
                                    params: { postId: post.id }
                                  });
                                  openLinkInNewTab(routeData.href);
                                }
                              "
                            >{{ post.en_title ? post.en_title : post.title }}</a
                            >
                          </h2>
                        </div>
                        <div
                            class="entry-content"
                            @click.exact="
                            () => {
                              $router.push({
                                name: 'EN_Post',
                                params: { postId: post.id }
                              });
                            }
                          "
                            @click.shift="
                            () => {
                              let routeData = $router.resolve({
                                name: 'EN_Post',
                                params: { postId: post.id }
                              });
                              openLinkInNewTab(routeData.href);
                            }
                          "
                        >
                          {{ post.en_summary ? post.en_summary : post.summary }}
                        </div>
                        <div style="display: flex; justify-content: space-between;">
                          <div class="mt-2" style="color: #6d5a3f">
                            <i class="fas fa-images mx-1" v-b-tooltip.top title="Record contains photo material" v-if="post.photos.length > 1"></i>
                            <i class="fas fa-volume-up mx-1" v-b-tooltip.top title="Record contains audio material" v-if="post.recordings.length > 0"></i>
                            <i class="fas fa-video mx-1" v-b-tooltip.top title="Record contains video material" v-if="post.videos.length > 0"></i>
                          </div>

                          <likes-system
                              :post="post"
                              class="mt-1"
                          ></likes-system>
                        </div>
                        <div class="post-meta mt-2">
                          <ul
                              class="pull-left list-inline author-meta text-left mb-0 pb-0"
                          >
                            <li class="list-inline-item font-normal pb-2">
                              <a
                                  class="regionLink"
                                  @click="
                                  () => {
                                    $router
                                      .push({
                                        name: 'EN_HomeWithRegionFilter',
                                        params: {
                                          region: post.region
                                        }
                                      })
                                      .catch(() => {});
                                  }
                                "
                              >
                                {{ $globalFunctions.getTranslatedRegion(post.region).toUpperCase() }}
                              </a>
                            </li>
                            <li class="date list-inline-item float-right">
                              {{
                                moment
                                    .unix(post.confirmedAt.seconds)
                                    .format("D. M. YYYY")
                              }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>


            <div  v-if="!isLoading">
              <div v-view="viewHandler">
                <a class="btn btn-primary d-block text-center" style="border-color: #e5d9bc; background: #e5d9bc" @click="() => { retrieveMorePosts();}">&nbsp;</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4 d-none d-lg-block">
        <Sidebar></Sidebar>
      </div>
    </div>

    <div id="fabButton">
      <fab
          :position="fabPosition"
          :bg-color="fabBgColor"
          :actions="fabActions"
          :fixed-tooltip="true"
          @newPost="
          () => {
            if ($store.getters.getUser !== null) {
              $router.push({ name: 'EN_EditPost' });
            } else {
              $refs['notSignedIn'].show();
            }
          }
        "
          @myPosts="
          () => {
            if ($store.getters.getUser !== null) {
              $router.push({ name: 'EN_MyPosts' });
            } else {
              $refs['notSignedIn'].show();
            }
          }
        "
      ></fab>

      <b-modal
          id="notSignedIn"
          ref="notSignedIn"
          hide-footer
          title="Dodajanje zapisov je omogočeno samo prijavljenim uporabnikom"
      >
        <div class="pb-5" style="height: 100%;">
          <div class="my-4 text-center">
            <a
                class="btn btn-link"
                @click.prevent.stop="
                () => {
                  $router.push({ name: 'SignIn' });
                }
              "
            >Prijavite se</a
            >
          </div>

          <div class="my-4 text-center">
            <a
                class="btn btn-link"
                @click.prevent.stop="
                () => {
                  $router.push({ name: 'Register' });
                }
              "
            >Registrirajte se</a
            >
          </div>

          <h6 class="mt-5">
            *Vsi registrirani uporabniki prejmejo 50 % popusta na 1 vstopnico v
            Slovenski etnografski muzej.
          </h6>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import Sidebar from "@/components/EN_Sidebar";
import {db} from "@/main";
import _ from "lodash";
import Loading from "vue-loading-overlay";
import UserAvatarWidget from "@/components/UserAvatarWidget";
import LikesSystem from "@/components/LikesSystem";
import fab from "vue-fab";

export default {
  name: "Home",
  components: {
    Sidebar,
    Loading,
    UserAvatarWidget,
    LikesSystem,
    fab,
  },
  data() {
    return {
      lastVisiblePost: null,
      posts: [],
      heroPost: null,
      config: null,
      categories: null,
      subcategories: null,
      isLoading: true,
      isLoadingOverlay: false,
      query: null,

      fabBgColor: "#69a637",
      fabPosition: "bottom-right",
      fabActions: [
        {
          name: "newPost",
          icon: "edit",
          tooltip: "New post"
        },
        {
          name: "myPosts",
          icon: "list",
          tooltip: "My posts"
        }
      ]
    };
  },
  metaInfo() {
    return {
      title: "Zapisi spomina",
      titleTemplate: null
    };
  },
  computed: {},
  beforeCreate() {
    const selectedLang = localStorage.getItem('zapisi_spomina_language');
    if(selectedLang !== null) {
      if(selectedLang === 'EN' && !this.$route.fullPath.includes('/en')) {
        this.$router.push({name: 'EN_' + this.$route.name}, this.$route.params);
      } else if(selectedLang === 'SI' && this.$route.fullPath.includes('/en')) {
        this.$router.push({name: this.$route.name.split('EN_')[1]}, this.$route.params);
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    console.log(to, from);
    if (to.name === "Post") {
      this.$store
          .dispatch(
              "setHomeOffsetTop",
              window.pageYOffset || document.documentElement.scrollTop
          )
          .then(() => {
            next();
          });
    } else {
      this.$store.dispatch("setHomeOffsetTop", 0).then(() => {
        next();
      });
    }
  },
  mounted() {
    const _this = this;

    _this.$globalFunctions.detectUserLocation();

    let p1 = _this.$store.dispatch("setConfig");
    let p2 = _this.$store.dispatch("setCategories");
    let p3 = _this.$store.dispatch("setUsers");

    Promise.all([p1, p2, p3]).then(() => {
      console.log("categories", _this.$store.getters.getCategories);
      _this.config = _this.$store.getters.getConfig;
      _this.categories = _this.$store.getters.getCategories;
      _this.subcategories = _this.$store.getters.getSubcategories;
      _this.users = _this.$store.getters.getUsers;

      _this.retrievePosts();
    });
  },
  methods: {
    openLink() {
      window.open("https://forms.gle/95cSFPf9zwCpTZmR6")
    },
    async retrievePosts() {
      const _this = this;

      _this.isLoading = true;

      console.log("$route", _this.$route);
      let categoryFilter = null;
      if (_this.$route.params && _this.$route.params.category) {
        categoryFilter = _this.$globalFunctions.getCategoryByAlias(
            _this.$route.params.category
        ).id;
      }

      let regionFilter = null;
      if (_this.$route.params && _this.$route.params.region) {
        regionFilter = _this.$route.params.region;
      }
      console.log("regionFilter", regionFilter);

      let authorFilter = null;
      if (_this.$route.params && _this.$route.params.author) {
        authorFilter = _this.$globalFunctions.getUserByDisplayName(
            _this.$route.params.author
        ).id;
      }

      let query = db.collection("posts");

      if (categoryFilter) {
        query = query.where("categoryId", "==", categoryFilter);
      } else if (regionFilter) {
        query = query.where("region", "==", regionFilter);
      } else if (authorFilter) {
        query = query.where("createdBy", "==", authorFilter);
      }

      query = query.where("confirmed", "==", true);

      await query
          .orderBy("confirmedAt", "desc")
          .limit(100).get()
          .then(function (_docs) {

            _this.lastVisiblePost = _docs.docs[_docs.docs.length-1];

            let posts = _docs.docs.map(doc => doc.data());
            // console.log("posts", posts);

            posts = _.sortBy(posts, ["recordFromNationalRegistry"], ["asc"]);

            _this.heroPost = _.find(posts, {
              id: _this.config.heroPost
            });

            _.remove(posts, {
              id: _this.config.heroPost
            });

            _this.posts = posts;

            setTimeout(() => {
              _this.isLoading = false;
            }, 10);

            setTimeout(() => {
              window.scrollTo({
                top: _this.$store.getters.getHomeOffsetTop,
                left: 0
              });
            }, 11);
          })
          .catch(error => {
            console.log("Error getting categories:", error);
            _this.isLoading = false;
          });
    },
    async retrieveMorePosts() {
      const _this = this;

     // _this.isLoading = true;

      await this.$store
          .dispatch(
              "setHomeOffsetTop",
              (window.pageYOffset || document.documentElement.scrollTop)
          )
          .then(() => {

          });

      console.log("$route", _this.$route);
      let categoryFilter = null;
      if (_this.$route.params && _this.$route.params.category) {
        categoryFilter = _this.$globalFunctions.getCategoryByAlias(
            _this.$route.params.category
        ).id;
      }

      let regionFilter = null;
      if (_this.$route.params && _this.$route.params.region) {
        regionFilter = _this.$route.params.region;
      }
      console.log("regionFilter", regionFilter);

      let authorFilter = null;
      if (_this.$route.params && _this.$route.params.author) {
        authorFilter = _this.$globalFunctions.getUserByDisplayName(
            _this.$route.params.author
        ).id;
      }

      let query = db.collection("posts");

      if (categoryFilter) {
        query = query.where("categoryId", "==", categoryFilter);
      } else if (regionFilter) {
        query = query.where("region", "==", regionFilter);
      } else if (authorFilter) {
        query = query.where("createdBy", "==", authorFilter);
      }

      query = query.where("confirmed", "==", true);

      console.log("_this.lastVisiblePost", _this.lastVisiblePost);
      if(!_this.lastVisiblePost) {
        return
      }

      await query
          .orderBy("confirmedAt", "desc")
          .startAfter(_this.lastVisiblePost)
          .limit(100).get()
          .then(function (_docs) {

            _this.lastVisiblePost = _docs.docs[_docs.docs.length-1];

            let posts = _docs.docs.map(doc => doc.data());
            // console.log("posts", posts);

            posts = _.sortBy(posts, ["recordFromNationalRegistry"], ["asc"]);

            _this.heroPost = _.find(posts, {
              id: _this.config.heroPost
            });

            _.remove(posts, {
              id: _this.config.heroPost
            });

            _this.posts = [..._this.posts, ...posts];

            setTimeout(() => {
              _this.isLoading = false;
            }, 10);

            setTimeout(() => {
              window.scrollTo({
                top: _this.$store.getters.getHomeOffsetTop,
                left: 0
              });
            }, 11);
          })
          .catch(error => {
            console.log("Error getting categories:", error);
            _this.isLoading = false;
          });
    },
    openLinkInNewTab(url) {
      setTimeout(() => {
        Object.assign(document.createElement("a"), {
          target: "_blank",
          href: url
        }).click();
      }, 10);
    },
    viewHandler(e) {
      if(e.type === 'enter') {
        this.retrieveMorePosts();
      }
      // console.log(e.type) // 'enter', 'exit', 'progress'
      // console.log(e.percentInView) // 0..1 how much element overlap the viewport
      // console.log(e.percentTop) // 0..1 position of element at viewport 0 - above , 1 - below
      // console.log(e.percentCenter) // 0..1 position the center of element at viewport 0 - center at viewport top, 1 - center at viewport bottom
      // console.log(e.scrollPercent) // 0..1 current scroll position of page
      // console.log(e.scrollValue) // 0..1 last scroll value (change of page scroll offset)
      // console.log(e.target.rect) // element.getBoundingClientRect() result
    }
  }
};
</script>
